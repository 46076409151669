/* CSS3 Document */

html, body {
    margin: 0;
    padding: 0;
    background: #121212;
    color: #fff;
}

body,table,td,th,textarea,input,button,select,strong,
body.home .tablet4x4 h2,
#footer h3, .productsHeader h1, .productsHeader h2,
.externalModal h2, .externalModal h3 {
    font-family: "Minion Pro", Garamond, "Adobe Garamond Pro", "Apple Garamond",
        "Arno Pro", "Adobe Caslon Pro", "Crimson Text", "Bitstream Charter",
        Baskerville, "Palatino Linotype", Palatino, Palladio, "URW Palladio L",
        "Book Antiqua", "Bookman Old Style", "Nimbus Roman No9 L",
        "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L",
        Georgia, serif;
    font-size: 1em;
}
h1,h2,h3, body.home .gallery p.large {
    font-family: geared_slab, "Roboto Slab", Rockwell, "ITC Lubalin Graph",
        "Palatino Linotype", Palatino, Palladio, "Palatino LT STD", "Book Antiqua",
        "Bookman Old Style", "Bitstream Charter", "New Century Schoolbook",
        "Century Schoolbook", "Century Schoolbook L", Georgia, serif;
}
body {
    font-size: 112.5%;
    line-height: 1.5;
}


header,footer,nav,article,section {display:block}


img, #logo object, .block object {max-width: 100%}
a img {border:0 none}

h1,
#footer h2,
.topBar .sectionName,
.productsHeader h1,
.productsHeader h2 {
    font-size: 2.67em;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 30px;
}
.topBar .sectionName {
    margin: 0;
}
.contentWrap>h1 {
    padding-left: 10px;
    padding-right: 10px;
}
h2,
.externalModal h1 {
    font-size: 1.67em;
    font-weight: 400;
    line-height: 1.2;
    margin: .5em 0 0;
}
h2.btnHdng {
    margin: 57px 0 12px;
}
.tabContent .section h2 {
    margin-top: 0;
}
h3,
h2.productSpecs {
    font-size: 1.33em;
    font-weight: 400;
    margin: 1em 0 .5em;
}
#footer h3 {
    font-size: 1.125em;
    font-weight: 600;
}
h4,
.externalModal h3 {
    font-size: 1em;
    font-weight: 400;
}
.externalModal h2 {
    font-size: 1em;
    font-weight: 700;
}
h5 {
    font-size: .889em;
    font-weight: 600;
}
h6 {
    font-size: .889em;
    font-weight: 400;
}


p {
    margin: 1em 0;
}
.section h2 + p {
    margin-top: 0;
}
p.lead-in {
    font-size: 1.67em;
    line-height: 1.2;
    color: #727372;
}
body.about .widerCol1 .col:first-child>p:first-child {
    font-size: 1.67em;
    line-height: 1.2;
    color: #727372;
}
p.lead-in:first-child,
body.about .widerCol1 .col:first-child>p:first-child {
    margin-top: 0;
}
blockquote {
    color: #727372;
    font-size: 1.56em;
    line-height: 1.29;
    margin: 1.5em 0;
}
blockquote p:first-child:before,
blockquote p:last-child:after {
    content: '\A0';
    background: url(../images/quote-icons.png) no-repeat 0 0;
    width: 24px;
    height: 20px;
    display: inline-block;
    margin: 0 .5em 0 0;
}
blockquote p:last-child:after {
    background-position: 100% 0;
    margin: 0 0 0 .5em;
    vertical-align: middle;
}
@media screen and (min-resolution:144dpi),(-webkit-min-device-pixel-ratio:1.5),(min--moz-device-pixel-ratio:1.5) {
    blockquote p:first-child:before,
    blockquote p:last-child:after {
        background-image: url(../images/quote-icons@2x.png);
        -webkit-background-size: 300% 100%;
        -moz-background-size: 300% 100%;
        background-size: 300% 100%;
    }
}


ul.nav,
ul.nav ul,
ul.social,
ul.slideshow,
form ul.cb,
.selectedFilters ul.filters,
ul.tabs,
ul.carousel,
ol.pagination {
    margin: 0;
    padding: 0;
    list-style: none;
}
em {
    font-style: normal;
    font-weight: bold;
}
em.italic {
    font-style: italic;
    font-weight: 400;
}
a {
    color: #e25829;
    text-decoration: none;
}
a:hover,
a:focus,
a:active,
a.readMore {
    text-decoration: underline;
}
a.readMore:hover,
a.readMore:focus,
a.readMore:active {
    text-decoration: none;
}

.nav a, ul.tabs a, #subFooter, #subFooter a {
    -moz-transition: color .5s, background-color .5s;
    -webkit-transition: color .5s, background-color .5s;
    -o-transition: color .5s, background-color .5s;
    transition: color .5s, background-color .5s;
}
.phone a, .fax a {color:inherit}

.offleft,
label.select .label {
    position: absolute;
    top: -4999px;
    left: -4999px;
}
html.jsOn .btnMenu .offleft {
    right: 9999px;
}
.btnMenu .offleft {
    top: auto;
    left: auto;
}
#pageWrapper .hideFromMobile {
    display: none;
}
#header:after,#menu:after,.twoCols:after,.threeCols:after,.fourCols:after,
.home .tablet4x4 .section:after,
.tabContent .section:after,.contentWrap:after,#footer .section:after,.clearfix:after {
    content:".";
    display:block;
    clear:both;
    height:0;
    visibility:hidden;
    font: 0px/0 serif;
}
#header,#menu,.twoCols,.threeCols,.fourCols,.contentWrap,.tabContent .section,
.home .tablet4x4 .section,
#footer .section,.clearfix {display:inline-block}
#header,#menu,.twoCols,.threeCols,.fourCols,.contentWrap,.tabContent .section,
.home .tablet4x4 .section,
#footer .section,.clearfix {display:block}

textarea,input,select, .button {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}


#pageWrapper {
    color: #3d3d3d;
    background: #f8f8f8;
}

#headerWrapper,
#mainContent,
#footerWrapper {
    min-width: 320px;
    margin: 0;
    position: relative;
    z-index: 10;
}
#mainContent {
    background: #f8f8f8;
    float: left;
    width: 100%;
}
#header,
.contentWrap .section {
    padding-left: 10px;
    padding-right: 10px;
}
.section.wideSect {
    padding: 0;
}

/***
    Header and Main Navigation
***/

#headerWrapper {
    background: #000;
    z-index: 12;
    border-bottom: 1px solid #3b3938;
    border-color: rgba(229,229,229,.2);
}
#header {
    padding: 10px 10px 0;
}
#logoWrapper {
    position: relative;
    margin: 10px 0 0 20px;
    float: left;
    z-index: 3;
}
#logo {
    padding: 10px 0;
}
#logo, #logo object, #logo img, #logo .link {
    width: 110px;
    height: 40px;
    display: block;
    margin: 0;
    border: 0 none;
    overflow: visible;
    -moz-transition: width .5s, height .5s;
    -webkit-transition: width .5s, height .5s;
    transition: width .5s, height .5s;
}
#logo .link {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    max-height: none;
    z-index: 9;
    padding: 10px 0;
}

#header div.mobileMenuBtn {
    float: right;
    border-left: 1px solid #2e2e2e;
    margin-bottom: 10px;
}
#header a.mobileMenuBtn {
    padding: 33px 33px 34px 40px;
    color: #999;
    color: rgba(255,255,255,.6);
    float: right;
    position: relative;
    text-decoration: none;
}
#header a.mobileMenuBtn span {
    border-top: 3px solid;
    border-bottom: 3px solid;
    padding: 2px 0;
    float: left;
    height: 3px;
    overflow: hidden;
    width: 16px;
}
#header a.mobileMenuBtn span,
#header a.mobileMenuBtn i.fa/*,
#header a.mobileMenuBtn img*/ {
    -moz-transition: border-color .5s, color .5s, background .5s;
    -webkit-transition: border-color .5s, color .5s, background .5s;
    -o-transition: border-color .5s, color .5s, background .5s;
    transition: border-color .5s, color .5s, background .5s;
}
#header a.mobileMenuBtn img {
    display: block;
    width: 100%;
    height: 3px;
    background: #999;
    background: rgba(255,255,255,.6);
}
#header a.mobileMenuBtn:hover,
#header a.mobileMenuBtn:active {
    color: #fff;
}
#header a.mobileMenuBtn:hover img,
#header a.mobileMenuBtn:active img {
    background: #fff;
}
@media all and (min-width:1px) {
    #header a.mobileMenuBtn img,
    #header a.mobileMenuBtn:hover img,
    #header a.mobileMenuBtn:active img {
        border-top: 3px solid;
        margin: 0 0 -3px;
        background: transparent;
    }
    /*
    #header a.mobileMenuBtn span:before {
        content: '\A0';
        display: block;
        width: 100%;
        height: 0;
        overflow: hidden;
        border-top: 3px solid;
    }*/
}
a.mobileMenuBtn i.fa {
    display: none;
}
a.mobileMenuBtn.mobileMenuOpen i.fa {
    display: block;
    position: absolute;
    background: #000;
    padding: .25em;
    top: 50%;
    left: 50%;
    margin: -.8em 0 0 -.5em;
}

#header .menu {
    clear: both;
    position: relative;
    margin: 0 -10px;
}
#header ul.nav {
    font-size: 1.33em;
    padding-top: 44px;
    border-top: 2.25em solid #000;
}

#header ul.nav ul,
#header ul.nav .dropdown {
    position: absolute;
    left: -9999px;
    top: -4999px;
}
#header ul.nav li.open ul,
#header ul.nav li.open .dropdown {
    position: relative;
    left: 0;
    top: 0;
}
#header ul.nav li .dropdown {
    padding: 20px;
}
#header ul.nav a {
    text-decoration: none;
    padding: 0.417em 30px;
    display: block;
    color: #adadad;
    color: rgba(255,255,255,.6);
}
#header ul.nav a:hover,
#header ul.nav a:focus,
#header ul.nav a:active,
#header ul.nav li.current>a,
#header ul.nav li.open>a {
    color: #fff;
    background: #2b2b2b;
}
ul.nav .fa.arrow {
    font-size: .75em;
    margin-top: .42em;
    float: right;
}
#header ul.nav ul {
    font-size: .75em;
}
#header ul.nav ul a {
    padding: 0.917em 30px;
}

#header .loginForm .field {
    margin-bottom: .417em;
}

#loginModal input,
#header .loginForm input { /* see modal styles */
    width: 100%;
}
#header .loginForm .button {
    width: 100%;
    font-size: .834em;
}

#loginModal {
    max-width: 372px;
}
#loginModal h2 {
    margin-top: 0;
}
#loginModal .field {
    position: relative;
}
#loginModal .field~.field {
    margin-top: 22px;
}

#loginModal label:nth-child(n):before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    width: 1.22em;
    height: 1em;
    text-align: center;
    position: absolute;
    left: 4999px;
    top: 4999px;
    margin: 0;
    padding: .44em .44em .5em;
    border-radius: 5px 0 0 5px;
    border: 1px solid #7a7b7a;
    background: #7a7b7a no-repeat center center;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 18 18'><title>Email</title><path fill='#FFF' d='M18 7.132v7.975q0 0.663-0.472 1.135t-1.135 0.472h-14.786q-0.663 0-1.135-0.472t-0.472-1.135v-7.975q0.442 0.492 1.015 0.874 3.636 2.471 4.992 3.465 0.573 0.422 0.929 0.658t0.949 0.482 1.105 0.246h0.020q0.512 0 1.105-0.246t0.949-0.482 0.929-0.658q1.708-1.235 5.002-3.465 0.573-0.392 1.004-0.874zM18 4.179q0 0.794-0.492 1.517t-1.225 1.235q-3.777 2.622-4.701 3.265-0.1 0.070-0.427 0.306t-0.542 0.382-0.522 0.326-0.578 0.271-0.502 0.090h-0.020q-0.231 0-0.502-0.090t-0.578-0.271-0.522-0.326-0.542-0.382-0.427-0.306q-0.914-0.643-2.632-1.833t-2.059-1.431q-0.623-0.422-1.175-1.16t-0.552-1.371q0-0.783 0.417-1.306t1.19-0.522h14.786q0.653 0 1.13 0.472t0.477 1.135z'></path></svg>");
    color: #fff;
}
#loginModal label[for=modalPassword]:before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='18' viewBox='0 0 12 18'><title>Password</title><path fill='#FFF' d='M3.214 7.714h5.143v-1.929q0-1.065-0.753-1.818t-1.818-0.753-1.818 0.753-0.753 1.818v1.929zM11.571 8.679v5.786q0 0.402-0.281 0.683t-0.683 0.281h-9.643q-0.402 0-0.683-0.281t-0.281-0.683v-5.786q0-0.402 0.281-0.683t0.683-0.281h0.321v-1.929q0-1.848 1.326-3.174t3.174-1.326 3.174 1.326 1.326 3.174v1.929h0.321q0.402 0 0.683 0.281t0.281 0.683z'></path></svg>");
}
#loginModal input {
    padding: 6px 5px 6px 6px;
}
#loginModal label:nth-child(n)~input {
    padding-left: 2.5em;
    height: calc(14px + 1.278em); /* spaces are required in calc() */
}

.forgotPass {
    text-align: right;
    margin: .25em 0;
}
#loginModal .buttons {
    margin-top: 16px;
}
#loginModal button {
    width: 100%;
}
#loginModal button[disabled],
#loginModal button:disabled {
    opacity: .5;
}
#loginModal h3 {
    margin: .33em 0 0;
}
#loginModal h2+h3 {
    margin: .54em 0 .375em;
}
#loginModal h3+p {
    margin: .25em 0 0;
}
#loginModal p:last-child {
    margin-bottom: 0;
}

.modalContent .orSeparator {
    font-size: 1.67em;
    text-align: center;
    font-style: italic;
    margin: .67em 0 .25em;
    line-height: 1;
}
.modalContent .orSeparator .sepTxt {
    background: #3d3d3d;
    padding: 0 .5em;
    position: relative;
}
.modalContent .orSeparator:before {
    content: '';
    display: block;
    height: 0;
    border-bottom: 1px solid #727372;
    position: relative;
    top: .5em;
}


.loginForm input::-webkit-input-placeholder {color: #999}
.loginForm input:-moz-placeholder {color: #999}
.loginForm input::-moz-placeholder {color: #999}
.loginForm input:-ms-input-placeholder {color: #999}
.loginForm input.placeholder {color: #999}
.loginForm input:focus::-webkit-input-placeholder {color: transparent}
.loginForm input:focus:-moz-placeholder {color: transparent}
.loginForm input:focus::-moz-placeholder {color: transparent}


#header ul.nav li,
#header .searchForm {
    border-top: 1px solid #2e2e2e;
}

#header li.search,
#header .searchForm a.close {
    display: none;
}
#header .searchForm {
    padding: 23px 30px 10px;
    text-align: center;
    top: 0;
    position: absolute;
    left: 0;
    right: 0;
}
#header .searchForm input {
    padding: .625em 1em;
    font-size: 1em;
    vertical-align: middle;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    border: 0 none;
    width: 98%;
    max-width: 400px;
}
#header .searchForm button {
    background: none repeat scroll 0 0 #fff;
    border: 0 none;
    color: #3d3d3d;
    cursor: pointer;
    display: inline-block;
    line-height: 1.25;
    padding: .25em .786em;
    margin: 0 0 0 -2.83em;
    position: relative;
    opacity: .5;
    vertical-align: middle;
}
#header .searchForm button:hover,
#header .searchForm button:focus {
    opacity: 1;
}
.searchForm button[type=submit]::-moz-focus-inner {padding:0;border:none}


/***
    Main Content Styles
***/

.contentWrap {
    float: left;
    width: 100%;
    background: #f8f8f8;
}
.contentWrap .contentWrap,
.contentWrap.externalModal {
    background: transparent;
}
.contentWrap .section {
    padding-top: 1px;
    clear: both;
}

h1+.contentWrap {
    margin-top: 3px;
}

.contentWrap.twoCols:last-child {
    margin-bottom: 20px;
}


.button {
    display: inline-block;
    color: #fff;
    /* two color gradient
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjNjQ2NDY0Ii8+DQo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMzZTNlM2UiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3VjZ2cpIiAvPg0KPC9zdmc+");
    */
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjM2UzZTNlIi8+DQo8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iIzY0NjQ2NCIvPg0KPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjM2UzZTNlIi8+DQo8L2xpbmVhckdyYWRpZW50Pg0KPHJlY3Qgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN1Y2dnKSIgLz4NCjwvc3ZnPg==");
    background: -moz-linear-gradient(top,#3e3e3e,#646464 50%,#3e3e3e); /* FF3.6+ */
    background: -webkit-gradient(linear,0 0,0 100%,from(#3e3e3e),color-stop(50%,#646464),to(#3e3e3e)); /* Chrome,Safari4+ */
    background: -o-linear-gradient(top,#3e3e3e,#646464 50%,#3e3e3e); /* Opera 11.10+ */
    background: linear-gradient(to bottom,#3e3e3e,#646464 50%,#3e3e3e); /* W3C */
    background-color: #3e3e3e; /* for old browsers */
    -webkit-background-size: auto 200%;
    -moz-background-size: auto 200%;
    background-size: auto 200%;
    background-position: 0 100%;
    -webkit-transition: background .5s, opacity .5s;
    -moz-transition: background .5s, opacity .5s;
    transition: background .5s, opacity .5s;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: inset 0 1px rgba(255,255,255,.4), 0 5px 5px rgba(0,0,0,.1);
    -moz-box-shadow: inset 0 1px rgba(255,255,255,.4), 0 5px 5px rgba(0,0,0,.1);
    box-shadow: inset 0 1px rgba(255,255,255,.4), 0 5px 5px rgba(0,0,0,.1);
    padding: 7px 8px 6px;
    border: 0 none;
    font-weight: bold;
    text-decoration: none;
    text-align: center;
    line-height: 1.3;
    text-shadow: 0 -1px rgba(0,0,0,.3);
    width: 9.05em;
}
.portfolio .contentWrap .button {
    text-decoration: none;
}
.button.wider {
    min-width: 9.05em;
    width: auto;
    padding: 7px 2em 6px;
}
.button:hover,
.button:focus,
.button:active {
    color: #fff;
    background-color: #646464;
/*
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjM2UzZTNlIi8+DQo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM2NDY0NjQiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3VjZ2cpIiAvPg0KPC9zdmc+");
    background: -moz-linear-gradient(top,#3e3e3e,#646464);
    background: -webkit-gradient(linear,0 0,0 100%,from(#3e3e3e),to(#646464));
    background: -o-linear-gradient(top,#3e3e3e,#646464);
    background: linear-gradient(to bottom,#3e3e3e,#646464);
*/
    background-position: 0 0;
    text-decoration: none;
}
.button.primary {
    color: #fff;
    background-color: #e05a32;
}
.button.primary,
.button.primary:disabled,
.button.primary[disabled],
.button.primary.disabled {
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZTA1YTMyIi8+DQo8c3RvcCBvZmZzZXQ9IjUwJSIgc3RvcC1jb2xvcj0iI2U2N2I1YiIvPg0KPHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZTA1YTMyIi8+DQo8L2xpbmVhckdyYWRpZW50Pg0KPHJlY3Qgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCN1Y2dnKSIgLz4NCjwvc3ZnPg==");
    background-image: -moz-linear-gradient(top,#e05a32, #e67b5b 50%, #e05a32);
    background-image: -webkit-gradient(linear,0 0,0 100%,from(#e05a32),color-stop(50%,#e67b5b),to(#e05a32));
    background-image: -o-linear-gradient(top,#e05a32,#e67b5b 50%,#e05a32);
    background-image: linear-gradient(to bottom,#e05a32,#e67b5b 50%,#e05a32);
}
.button.primary:hover,
.button.primary:focus,
.button.primary:active {
    background-color: #e67b5b;
}
.button.primary:disabled,
.button.primary[disabled],
.button.primary.disabled {
    color: #fff;
    color: rgba(255,255,255,.8);
    background-color: #e67b5b;
    background-position: 0 0;
}
button[type='submit'].button {
    -moz-appearance: none !important;
    -webkit-appearance: none !important;
}

/*
.button.success {

}
.button.success:hover,
.button.success:focus,
.button.success:active {
    background: #4e9e3b;
}
.button.info {

}
.button.info:hover,
.button.info:focus,
.button.info:active {
    background: #84d1e9;
}
.button.warning {


}
.button.warning:hover,
.button.warning:focus,
.button.warning:active {
    background: #ffbd50;
}
.button.danger {

}
.button.danger:hover,
.button.danger:focus,
.button.danger:active {
    background: #db0303;
}
*/
.button:disabled,
.button[disabled],
.button.disabled {
    color: #fff;
    background-color: #9a9a9a;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjYWRhZGFkIi8+DQo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiM5YTlhOWEiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3VjZ2cpIiAvPg0KPC9zdmc+");
    background-image: -moz-linear-gradient(top,#adadad,#9a9a9a);
    background-image: -webkit-gradient(linear,0 0,0 100%,from(#adadad),to(#9a9a9a));
    background-image: -o-linear-gradient(top,#adadad,#9a9a9a);
    background-image: linear-gradient(to bottom,#adadad,#9a9a9a);
    font-weight: bold;
}
.button.block, .button.mobileBlock {
    display: block;
    clear: both;
    width: auto;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.breadcrumbs {
    background: #fcfcfc;
    border-bottom: 1px solid #ccc;
    padding: .8em 30px;
    color: #ccc;
    font-size: .889em;
    float: none;
    width: auto;
}
.breadcrumbs+h1 {
    margin-top: .245em;
}
.breadcrumbs a {
    padding: .75em;
    color: #e25829;
}
.breadcrumbs a:after {
    content: '\A0';
    color: #d8d8d8;
    margin: 0 -.75em 0 .75em;
    display: inline-block;
    width: 0;
    height: 1em;
    border-right: 1px solid;
    vertical-align: text-bottom;
}
.breadcrumbs .current {
    color: #3d3d3d;
    padding: .75em;
}
.breadcrumbs.contentWrap a .fa,
body.products .breadcrumbs.contentWrap a .fa {
    color: #e25829;
}


.twoCols, .threeCols, .grid4x4, .tablet4x4, .carouselWrap ul.carousel,
#footer .contentWrap,.productsListing {
    w\ord-spacing: -.43em;
}
body.details .productFilters .fourCols {
    w\ord-spacing: -.31em;
}
:root .twoCols, :root .threeCols, :root .grid4x4, :root .tablet4x4,
:root .carouselWrap ul.carousel,
:root #footer .contentWrap,.productsListing {
    letter-spacing: -.31em;
}
.twoCols .col, .threeCols .col, .twoCols .doubleCol, .grid4x4.col, .tablet4x4 .col,
body.details .productFilters .fourCols .col,
.carouselWrap ul.carousel li,
#footer .col,
.productBox, h1, h2 {
    word-spacing: normal;
    letter-spacing: normal;
}
.contentWrap.threeCols:not(:-moz-any-link) {word-spacing: .05em}

.contentWrap.grid4x4 {
    margin: 0 -2px;
    left: 3px;
    position: relative;
    text-align: center;
    float: none;
    width: auto;
}
.grid4x4 .col {
    display: inline-block;
    vertical-align: top;
    width: 44.83%;
    margin: 2%;
    min-width: 138px;
    max-width: 170px;
    min-height: 89px;
}
@media all and (min-width:600px) {
    .contentWrap.grid4x4 {
        max-width: 574px;
        margin: 0 auto;
    }
}


/***
    Home Page
***/

body.home h1 {
    margin: 0 0 1px;
}

body.home #mainContent .contentWrap {
    position: relative;
    z-index: 31;
    background: #f8f8f8;
    padding: 54px 0 40px;
}
body.home #mainContent .contentWrap.fourCols {
    background: #f1f1f1;
    text-align: center;
}
@media all and (min-width:500px) {
    body.home #mainContent .contentWrap.threeCols {
        margin-bottom: 38px;
    }
    body.home #mainContent .contentWrap.fourCols {
        padding: 90px 0 30px;
    }
}
body.home #mainContent .grid4x4 {
    padding: 1.25em 0 0;
}

body.home .contentWrap.gallery {
    text-align: center;
    color: #fdfdfd;
    background: #24160e; /* url(../images/hero-bg.jpg) 50% no-repeat */
    background-color: rgba(36,22,14,.8);
    background: rgba(0,0,0,0);
    position: static;
    /*
    height: 900px;
    height: 540px;*/
}
body.home #mainContent .contentWrap.gallery {
    padding: 0;
}
body.home .section.gallery {
    position: relative;
    z-index: 9;
    padding: 30px 0 0;
}

body.home .galleryBg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: #130804;
    height: 900px;
    overflow: hidden;
}
.galleryBg img {
    width: 100%;
    min-width: 544px;
    left: -112px;
    right: -112px;
    margin: 0 auto;
    top: 0;
    position: absolute;
    min-height: 482px;
    min-height: 275px;
    min-height: 386px;
}

body.home .gallery p {
    font-size: 1.11em;
    line-height: 1.6;
    margin: 0 10px;
}
body.home .gallery p.large {
    font-size: 2em;
    line-height: 1;
    margin-bottom: 4px;
}

body.home .gallery .buttons {
    margin: -.5em 0 6px;
    position: relative;
}

@media all and (min-width:560px) {
    body.home .section.gallery {
        padding-top: 50px;
    }
    body.home .gallery p.large {
        margin-bottom: 24px;
    }
    body.home .gallery .buttons {
        margin-bottom: 26px;
    }
}

body.home .gallery .buttons:after {
    content: '\A0';
    width: 78%;
    margin: 0 auto;
    height: 1.33em;
    display: block;
    border-top: 1px solid #e5e5e5;
    opacity: .2;
}
body.home .gallery .buttons .button {
    position: relative;
    bottom: -.875em;
    z-index: 9;
    font-size: 1.33em;
    width: 8.3em;
    padding: 5px 8px 4px;
}
@media all and (min-width:460px) {
    body.home .gallery .buttons .button {
        bottom: -.925em;
        width: 10.85em;
    }
}

body.home .carouselWrap {
    position: relative;
    background: url(../images/table-top-bg.jpg) 50% 100% repeat-x;
    padding-bottom: 66px;
    max-height: 430px;
    overflow: hidden;
}
body.home .slick-carousel {
    visibility: hidden;
}
.carouselWrap .slick-arrows {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    height: 0;
    visibility: hidden;
}
.slick-carousel .slide {
    padding: 22px 23px 22px 22px;
}
.slick-carousel .slide a {
    color: #fdfdfd;
    text-decoration: none;
}


.home .slick-carousel .photoWrap,
.productsListing .productPhoto,
#mainPhoto .productPhoto {
    position: relative;
    display: block;
    padding-bottom: 2.3%;
}
.productsListing .productPhoto {
    display: inline-block;
    max-width: 480px;
    width: 100%;
}
body.molding-veneers .productsListing .productPhoto {
    width: 100%;
}

#mainPhoto .productPhoto {
    padding-bottom: 2.8%;
}
.home .slick-carousel .photoWrap:before {
    content: '\A0';
    position: absolute;
    width: 100%;
    padding-top: 129.6%;
    height: 0;
    top: 0;
    left: 0;
    background: url(../images/door-lighting-overlay270.png) no-repeat;
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    z-index: 4;
}
.home .slick-carousel .photoWrap:after,
.productsListing .productPhoto:after,
#mainPhoto .productPhoto:after {
    content: ' ';
    position: absolute;
    width: 125.2%;
    left: -12.6%;
    top: auto;
    bottom: 0;
    padding-bottom: 5%;
    background: url(../images/door-shadow270.png) no-repeat 50% 100%;
    -moz-background-size: 100% auto;
    -webkit-background-size: 100% auto;
    background-size: 100% auto;
    z-index: 1;
}
.home .slick-carousel .photoWrap img,
.productsListing .productPhoto img,
#mainPhoto .productPhoto img {
    position: relative;
    z-index: 3;
    vertical-align: top;
}
.productsListing .productPhoto img {
    width: 100%;
}

body.molding-veneers .productsListing .productPhoto:after,
body.molding-veneers #mainPhoto .productPhoto:after {
    display: none;
}

.home .slick-carousel .slide-fade {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    padding-top: 129.7%;
    opacity: 0;
    z-index: 99;
    background: #000;
}
.home .slick-carousel .photoWrap:before,
.home .slick-carousel .slide-fade {
    -webkit-backface-visibility:hidden;
    -moz-backface-visibility:hidden;
    -ms-backface-visibility:hidden;
    -o-backface-visibility:hidden;
    backface-visibility:hidden;
    /* Fix of Webkit flickering */
}

body.home #mainContent .button {
    font-weight: 400;
}
body.home #mainContent .button i.fa {
    margin-left: .33em;
}

body.home .grid4x4 .col {
    position: relative;
}


body.home .grid4x4 a {
    display: block;
}

body.home .grid4x4 img,
body.home .fourCols img {
    width: 100%;
    display: block;
}

body.home .grid4x4 a {
    text-decoration: none;
    display: block;
}
body.home .grid4x4 a:hover:before,
body.home .grid4x4 a:focus:before,
body.home .grid4x4 a:active:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255,255,255,.5) no-repeat center center;
    z-index: 8;
    top: 0;
}
body.home .grid4x4 a.magnify:hover:before,
body.home .grid4x4 a.magnify:focus:before,
body.home .grid4x4 a.magnify:active:before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='28' height='30' viewBox='0 0 28 30'><title>Enlarge</title><path fill='#FFF' d='M19.286 13.929q0-3.097-2.201-5.299t-5.299-2.201-5.299 2.201-2.201 5.299 2.201 5.299 5.299 2.201 5.299-2.201 2.201-5.299zM27.857 27.857q0 0.871-0.636 1.507t-1.507 0.636q-0.904 0-1.507-0.636l-5.742-5.725q-2.997 2.076-6.68 2.076-2.394 0-4.579-0.929t-3.767-2.511-2.511-3.767-0.929-4.579 0.929-4.579 2.511-3.767 3.767-2.511 4.579-0.929 4.579 0.929 3.767 2.511 2.511 3.767 0.929 4.579q0 3.683-2.076 6.68l5.742 5.742q0.619 0.619 0.619 1.507z'></path></svg>");
}
body.home .grid4x4 a.videoLightbox:hover:before,
body.home .grid4x4 a.videoLightbox:focus:before,
body.home .grid4x4 a.videoLightbox:active:before {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='18' viewBox='0 0 14 18'><title>Play</title><path fill='#FFF' d='M13.902 9.311l-13.339 7.413q-0.231 0.131-0.397 0.030t-0.166-0.362v-14.786q0-0.261 0.166-0.362t0.397 0.030l13.339 7.413q0.231 0.131 0.231 0.311t-0.231 0.311z'></path></svg>");
}
body.home .grid4x4 a.videoLightbox:before,
body.home .grid4x4 a.videoLightbox:after {
  border: 5px solid #fff;
    opacity: .7;
  -moz-border-radius: 9em;
  -webkit-border-radius: 9em;
  border-radius: 9em;
  content: "\a0";
  height: 0;
  width: 0;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  margin: auto;
  overflow: hidden;
  padding: 12%;
  position: absolute;
}
body.home .grid4x4 a.videoLightbox:after {
  border-color: transparent #fff;
  border-width: .72em 0 .72em 1.08em;
    border-right: 0 none;
  left: 2.4%;
    padding: 0;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  font-size: 1em;
}


body.home .tablet4x4 .col {
    margin: 0 0 60px;
}


@media all and (min-width:460px) {
    body.home .tablet4x4 .section {
        padding: 0;
    }
    body.home .tablet4x4 .colWrap {
        float: left;
        width: 100%;
        position: relative;
    }
    body.home .tablet4x4 .col {
        display: inline-block;
        vertical-align: top;
        width: 44.8%;
        margin: 0 2.55% 60px;
        padding-bottom: 2em;
    }
    body.home .contentWrap .btnMenu,
    body.home .contentWrap p.btnWrap {
        bottom: 60px;
        position: absolute;
        width: 44.8%;
        margin: 0;
    }
}
body.home .tablet4x4 h2 {
    font-size: 1.34em;
    margin-bottom: .7em;
}


.btnMenu {
    position: relative;
    margin: 1em 0;
}
.btnMenu ul.nav {
    text-align: left;
    padding: 20px 0;
    font-size: .89em;
    background: #fff;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}
.btnMenu ul.nav a {
    display: block;
    padding: 0 20px;
    color: #3d3d3d;
    text-decoration: none;
}
.btnMenu ul.nav a:hover,
.btnMenu ul.nav a:focus,
.btnMenu ul.nav a:active {
    text-decoration: none;
    background: #f1f1f1;
}
.btnMenu ul.nav {
    position: absolute;
    min-width: 12.5em;
    right: 0;
}


/***
    Product Listings
***/

.contentWrap.products {

    background: #d4d4d4; /* Old browsers */
    background: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDI9IjAlIiB5Mj0iMTAwJSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjZDRkNGQ0Ii8+DQo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlOGU4ZTgiLz4NCjwvbGluZWFyR3JhZGllbnQ+DQo8cmVjdCB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI3VjZ2cpIiAvPg0KPC9zdmc+");
    background: -moz-linear-gradient(top,#d4d4d4,#e8e8e8);
    background: -webkit-gradient(linear,0 0,0 100%,from(#d4d4d4),to(#e8e8e8));
    background: -o-linear-gradient(top,#d4d4d4,#e8e8e8);
    background: linear-gradient(to bottom,#d4d4d4,#e8e8e8);
    padding-bottom: 60px;
}
.productsHeader,
.sectionHeader {
    padding: 0 10px;
}
.productsHeader {
    padding-top: 10px;
}
.productsHeader h1,
.productsHeader h2 {
    font-size: 1.67em;
    margin: 0 0 3px;
    padding-top: 17px;
}
.productsHeader .showFilters {
    position: relative;
    padding: 0 6px 20px 0;
    float: left;
}
body.details .productsHeader .showFilters {
    display: none;
}
.productsHeader .showFilters.pointer:before,
.productsHeader .showFilters.pointer:after {
    content: '\A0';
    position: absolute;
    right: 0;
    bottom: -1px;
    width: 0;
    height: 0;
    border-color: #e3e3e3 transparent;
    border-style: none solid solid;
    border-width: 0 10px 10px;
    margin-right: 1px;
}
.productsHeader .showFilters.pointer:before {
    border-bottom-color: #c3c3c3;
    border-bottom-color: rgba(178,178,178,.5);
    border-width: 0 11px 11px;
    margin-right: 0;
}
.showFilters a {
    color: #3d3d3d;
    font-size: .889em;
}
.showFilters .fa-filter {
    margin-right: .3em;
}
.showFilters .arrow {
    margin-left: 1em;
}
.productFilters.section {
    padding: 30px 20px;
    background: #e5e5e5;
    background: rgba(241,241,241,.5);
    border-top: 1px solid #c3c3c3;
    border-bottom: 1px solid #c3c3c3;
    border-color: rgba(174,174,174,.5);
    clear: both;
}
body.details .productFilters.section {
    display: none;
}
.productFilters.section .contentWrap {
    float: none;
}
.productFilters .buttons {
    text-align: center;
    margin: 0 0 20px;
}

.productFilters fieldset {
    position: static;
    width: 100%;
    border: 0 none;
    margin: 0;
    padding: 0;
    border-bottom: 1px solid #c3c3c3;
    border-color: rgba(61,61,61,.2);
}
.productFilters legend {
    display: inline-block;
    width: 100%;
    border: 0 none;
    margin: 0;
    padding: 0;
}
.productFilters legend .legend,
.productFilters legend .legend a {
    display: block;
    line-height: 2;
    color: #3d3d3d;
}
.productFilters .countBadge {
    display: inline-block;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    background: #3e3e3e;
    color: #fff;
    padding: 0 .3em;
    line-height: 1.6;
    min-width: .67em;
    margin-left: .3em;
    text-align: center;
    text-decoration: none;
}
.productFilters:not(:-webkit-any-link) .countBadge {
    padding: .1em .3em 0;
    line-height: 1.5;
}
.productFilters legend .fa {
    line-height: 2;
}
.productFilters .fieldset {

}
.productFilters .vis-icon {
    float: right;
}
.productFilters ul.cb {
    padding: 0 0 .86em .33em;
}
.productFilters ul.cb label {
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
.productFilters ul.cb label:hover .label,
.productFilters ul.cb input:focus~.label {
    color: #e05a32;
    text-decoration: underline;
}
.productFilters ul.cb input:disabled~.label,
.productFilters ul.cb input[disabled]~.label {
    opacity: .65;
    color: #3d3d3d;
    text-decoration: none;
}

.resultsInfo {
    clear: both;
    padding: 25px 0 0;
}
body.products .resultsInfo+.contentWrap {
    clear: both;
    padding-top: 22px;
}
.resultsInfo .selectedFilters li {
    float: left;
    margin: 0 1.5em 0 0;
}
.resultsInfo .selectedFilters a {
    color: #3d3d3d;
    font-size: .889em;
}
body.products.details .resultsInfo .selectedFilters a {
    margin-left: .5em;
}
.resultsInfo .resultsNum {
    clear: both;
}

.productsListing {
    clear: both;
    padding-top: 22px;
}

.productsListing .productBox {
    text-align: center;
}
.productsListing .productBox a {
    color: #e25829;
    display: block;
    display: inline-block;
    width: 100%;
    max-width: 320px;
}
.productsListing .productBox a:hover span,
.productsListing .productBox a:active span,
.productsListing .productBox a:focus span {
    text-decoration: underline;
}
.productsListing .productInfo {
    display: block;
}
.productsListing .productName {
    float: left;
}
.productsListing .productBox .price {
    float: right;
}

/***
    Product Details
***/


body.details .contentWrap.products>.section {
    overflow-x: hidden;
}

body.details .contentWrap.products {
    padding-bottom: 0;
}

.productFilters .instructions {
    font-size: 1.33em;
    text-align: center;
}
.productFilters .drawer label {
    display: inline-block;
    max-width: 100%;
}
.productFilters .drawer .name {
    border-bottom: 1px solid #c3c3c3;
    border-color: rgba(61,61,61,.2);
    padding-bottom: .25em;
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;
}
.productFilters .drawer .name .moreInfo {
    display: none;
}

.productFilters .drawer label img {
    pointer-events: none; /* to fix a bug in IE11 */
}
body.details .productFilters .drawer input {
    float: right;
}
body.details .productFilters .drawer.selected input {
    position: absolute;
    top: -4999px;
    left: -4999px;
}


body.products.details #mainPhoto {
    margin: 0 15px;
}
#mainPhoto .productPhoto {
    margin: 0 0 1em;
}
.pickedDrawer {
    position: relative;
    margin-bottom: 7px;
    max-width: 100%;
}
.pickedDrawer img {
    vertical-align: top;
}
.pickedDrawer a.remove {
    background: #e8e8e8;
    color: #3d3d3d;
    position: absolute;
    top: -.58em;
    right: -.58em;
    -moz-border-radius: 19em;
    -webkit-border-radius: 19em;
    border-radius: 19em;
    padding: .133em;
    font-size: 1.7em;
    line-height: 1;
    height: .95em;
    width: .95em;
    text-align: center;
}
.pickedDrawer .remove i.fa {
    margin-top: -1px;
}

#colorbox .pickedDrawer {
    max-width: 540px;
}
#colorbox .productPhoto {
    margin: 0;
    height: 700px;
    width: 540px;
    img {
        display: block;
    }
}
#colorbox .productPhoto img[src*=".svg"],
#colorbox .productPhoto object[data*=".svg"] {
    width: 540px;
    min-height: 540px;
    max-height: 100%;
}

a.magnify {
    display: block;
    max-width: 100%;
}
#mainPhoto a.magnify {
    font-size: .778em;
    color: #3d3d3d;
    display: block;
    text-align: center;
}
#mainPhoto a.magnify i.fa {
    margin-right: .2em;
}

h2.productSpecs {
    margin-bottom: .33em;
}
p.productSpecs {
    font-size: .778em;
    margin: 0;
}

.btnHdng.calcDoorSize {
    padding-left: 24px;
}
.btnHdng.calcDoorSize .icon {
    float: left;
    margin: .1em 0 0 -24px;
    width: 19px;
    height: 24px;
}


.calcDoorSize.contentWrap {
    float: none;
}
#calcDoorSize h2, #cboxContent #calcDoorSize h1 {
    margin: 0 0 22px;
    line-height: 1.2;
}
#calcDoorSize table {
    border-top: 1px solid #727372;
    border-collapse: collapse;
    width: 31.67em;
}
.externalModal #calcDoorSize table {
    /*min-width: 20.67em;*/
    width: 100%;
}
#calcDoorSize th, #calcDoorSize td {
    padding: 6px 0;
    display: block;
    border: 0 none;
    border-bottom: 1px solid #727372;
}
#calcDoorSize th {
    border: 0 none;
    text-align: left;
    font-weight: 400;
}
@media all and (min-width:32em) {
    #calcDoorSize th, #calcDoorSize td {
        border-top: 1px solid #727372;
        padding: 6px 0 5px 15px;
        display: table-cell;
    }
    #calcDoorSize th {
        border: 1px solid #727372;
        border-left: 0 none;
        width: 10.67em;
        padding: 6px 15px 5px 0;
    }
}

#header .loginForm input,
.modalContent input,
.modalContent select {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
    color: #666;
    padding: 5px 5px 5px 6px;
}
.modalContent td input {
    padding: 6px 10px;
}
.modalContent select,
.modalContent input[type=text] {
    min-height: 37px;
}
.modalContent input:disabled,
.modalContent input[disabled],
.modalContent select:disabled,
.modalContent select[disabled] {
    background: #aaa;
    border-color: #888;
}
.modalContent select:not(:-webkit-any-link) {
    line-height: 31px;
}
#calcDoorSize td input,
#calcDoorSize select {
    width: 100%;
}
#calcDoorSize .buttons {
    text-align: right;
    padding: 14px 0 0;
}
#calcDoorSize .buttons .button {
    margin-left: 26px;
    vertical-align: top;
}

/* may want to namespace these... */
input::-webkit-input-placeholder {color: #666}
input:-moz-placeholder {color: #666}
input::-moz-placeholder {color: #666; opacity:1}
input:-ms-input-placeholder {color: #666}
input.placeholder {color: #666}
input:focus::-webkit-input-placeholder {color: transparent}
input:focus:-moz-placeholder {color: transparent}
input:focus::-moz-placeholder {color: transparent}


#calcPrice {
    padding-bottom: 1px;
}
#calcPrice h2 {
    margin: 0 0 25px;
    line-height: 1.2;
}
.modalContent .contentWrap,
.contentWrap.externalModal {
    background: transparent;
}
.modalContent .contentWrap.twoCols {
    margin: 0 0 1.1em;
}
.modalContent .contentWrap.twoCols:last-child {
    margin: 0;
}

#calcPrice input,
#calcPrice select {
    margin: 0;
}
input.plainRO {
    border: 1px solid #3d3d3d;
    border-color: rgba(0,0,0,0);
    background: transparent;
    font: inherit;
    vertical-align: middle;
    margin: 0;
    max-width: 99%;
}
#cboxContent .plainRO {color:#fff}
#calcPrice p {
    margin: 0 0 .85em;
    font-weight: 700;
    clear: both;
}
#calcPrice .col p {
    margin: 0;
}
#calcPrice label {
    font-weight: 700;
}
#calcPrice .label {
    width: 45%;
    display: inline-block;
}
#calcPrice .borders {
    display: inline-block;
    margin-bottom: 5px;
    width: 46%;
}
#calcPrice label[for=doorMaterial]+.borders {
    width: 100%;
}
#calcPrice select {
    width: 300px;
    max-width: 99%;
}



.contentWrap .section.share {
    text-align: center;
    border-width: 1px 0;
    border-style: solid none;
    border-color: #e2e2e2;
    border-color: rgba(204,204,204,.5);
    background: #f4f4f4;
    background: rgba(241,241,241,.5);
    padding: 30px 10px 24px;
    max-width: none;
}
body.about .contentWrap .section.share,
body.blog .contentWrap .section.share {
    border-color: #f1f1f1;
    background: #f1f1f1;
}
.section.share .inline,
.share ul.social,
.share .social li,
.share .social i.fa {
    display: inline-block;
    vertical-align: middle;
    vertical-align: baseline;
    margin: 0;
}
.share ul.social {
    margin: .5em 0 1em;
}
.share ul.social li {
    font-size: 1.67em;
    line-height: 1.1;
    margin: 0 .29em;
}
.blogHeader .share .social li {
    margin: 0 .36em 0 0;
    font-size: 1.56em;
}
.share .social a {
    color: #3d3d3d;
    opacity: .5;
    display: block;
    -webkit-transition: opacity .5s;
    -moz-transition: opacity .5s;
    transition: opacity .5s;
}
.share .social a:hover,
.share .social a:focus,
.share .social a:active {
    opacity: 1;
}
.share .social i.fa {
    font-size: 1.09em;
}
.share .social .pinterest i.fa {
    font-size: 1.05em;
}


.availableOptions .sectionHeader p {
    margin: .67em 0 1.55em;
}
.availableOptions .sectionHeader .tabsContainer {
    margin-top: 1em;
}


ul.tabs {
    padding: 0 15px;
}
.tabsContainer ul.tabs {
    padding: 0;
}

ul.tabs li,
.tabsContainer.swiper .slideInner {
    float: left;
    margin: 10px .29em -1px 0;
    position: relative;
    z-index: 99;
}
.tabsContainer.swiper .slideInner {
    margin-bottom: 0;
}
.tabsContainer a,
ul.tabs a {
    display: inline-block;
    color: #3d3d3d;
    border-bottom: 0 none;
    -webkit-border-radius: 10px 10px 0 0;
    -moz-border-radius: 10px 10px 0 0;
    border-radius: 10px 10px 0 0;
    padding: .458em .5em;
    line-height: 1.33;
    text-decoration: none;
    text-align: center;
    max-width: 8em;
    border: 1px solid #b3b4b3;
    border-bottom: 0 none;
}
ul.tabs a.selected,
ul.tabs a:hover,
ul.tabs a:focus,
ul.tabs a:active,
.tabsContainer a:focus,
.tabsContainer a:active {
    color: #fff;
    background: #b3b4b3;
    text-decoration: none;
}
ul.tabs .tabTxt,
.tabsContainer.swiper .tabTxt {
    display: inline-block;
    vertical-align: middle;
}
ul.tabs li a:after,
.tabsContainer.swiper .slideInner a:after {
    content: '.';
    height: 2.67em;
    display: inline-block;
    width: 1px;
    margin-left: -1px;
    visibility: hidden;
    vertical-align: middle;
}

@media screen and (min-width:741px) {
    ul.tabs a {padding: .916em 1em}
}

.tabsContainer.swiper .tabs li {
    margin: 0;
    font-size: 1em;
}
.tabsContainer.swiper {
    padding: 0 1.5em;
    position: relative;
    width: auto;
    margin-bottom: -1px;
}
ul.tabs li.swiper-slide {
    width: auto;
}
.tabsContainer .prev,
.tabsContainer .next {
    position: absolute;
    bottom: .6em;
    left: 0;
    z-index: 99;
    padding: .25em;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    border: 0 none;
    display: none;
}
.tabsContainer .next {
    left: auto;
    right: 0;
}
.tabsContainer .arrow {
    border-top: .5em solid;
    border-left: .5em solid;
    border-bottom: .5em solid;
    border-color: transparent #000;
    display: block;
    height: 0;
    width: 0;
}
.tabsContainer .prev .arrow {
    border-left: 0 none;
    border-right: .5em solid #000;
}
.tabsContainer.swiper .prev,
.tabsContainer.swiper .next {
    display: block;
}


.tabContent, .contentWrap.tabContent {
    background: #b3b4b3;
}

.contentWrap.profileGrid .section {
    padding: 30px 10px 20px;
}
.contentWrap.profileGrid.fourCols .section {
    text-align: center;
}
.contentWrap.profileGrid.fourCols .col {
    padding-top: 30px;
    display: inline-block;
    text-align: left;
    max-width: 100%;
    min-height: 192px;
    min-width: 120px;
}

.profileGrid .col>img,
.profileGrid .col .magnify img {
    display: block;
    margin: 0 auto .25em;
}
.profileGrid img[src*=".svg"],
.profileGrid object[data*=".svg"] {
  width: 100%;
  height: auto;
}
.profileGrid .caption {
}
.profileGrid .disclaimer {
    float: left;
    position: relative;

    strong {
        display: block;
        width: 1.1em;
        height: 1.1em;
        margin-right: 0.75em;
        line-height: 1.35;
        text-align: center;
        border: 2px solid #d44;
        color: #d44;
        border-radius: 50%;
    }

    .disclaimer-text {
        display: none;
        position: absolute;
        top: 0;
        left: 1.5em;
        width: 18em;
        padding: 0.5em 0.75em;
        font-size: 14px;
        background: #eee;
        border: 1px solid #aaa;
    }

    &:hover .disclaimer-text {
        display: block;
    }
}
body.products .contentWrap a i.fa {
    color: #3d3d3d;
}


body.details .relatedProducts {
    background: #232221;
    padding: 56px 0 51px;
}
body.details .relatedProducts h2 {
    color: #f8f8f8;
    text-align: center;
    margin: 0 0 21px;
}
.carousel .swiper-slide {
    float: left;
    width: 170px;
    width: 186px;
    padding: 0 10px;
    display: inline-block;
    vertical-align: bottom;
    float: none;
}
body.products.details .carouselWrap a {
    display: block;
    max-width: 200px;
    color: #f1f1f1;
}
.carouselWrap .caption {
    display: block;
}
body.details .relatedProducts .prev,
body.details .relatedProducts .next {
    display: none;
}


/***
    Shipping Dates modal
***/

body.home #mainContent .externalModal.shippingDates,
body.home #mainContent .externalModal.calcDoorSize {
    background: transparent;
    padding: 0;
}

#cboxContent .externalModal>.section {
    padding: 0 0 1px;
}
.externalModal h1,
#cboxContent .externalModal h1,
.externalModal h3,
#cboxContent .externalModal h3 {
    margin: 0;
}
.externalModal h2 {
    margin: 21px 0 23px;
}
.externalModal.shippingDates h3:first-of-type {
    border-top: 1px solid #727372;
}
.externalModal.shippingDates h3 a {
    padding: 12px 20px 10px;
    color: #fff;
    display: block;
    border-bottom: 1px solid #727372;
}
.externalModal.shippingDates h3 a.open {
    background: #484848;
}
.externalModal.shippingDates h3 i.fa {
    float: right;
    margin-top: .2em;
}
.externalModal.shippingDates table {
    border-collapse: collapse;
    background: #333;
    width: 100%;
}
.externalModal th,
.externalModal td {
    padding: 10px 15px;
}
.externalModal.shippingDates th,
.externalModal.shippingDates td {
    padding: 10px;
}
.externalModal td,
.externalModal th {
    border-top: 1px solid #727372;
    border-left: 1px solid #727372;
}
.externalModal tbody th:first-child {
    border-left: 0 none;
    padding-left: 10px;
    text-align: left;
}
.externalModal thead th,
.externalModal tr.thead th {
    text-align: left;
    border: 0 none;
}
.externalModal tr.thead th {
    border-top: 1px solid #727372;
}
.externalModal thead th:first-child,
.externalModal tr.thead th:first-child {
    text-align: center;
    padding-left: 10px;
}
.externalModal.shippingDates th:first-child~td {
    width: 5em;
}
.externalModal.shippingDates th:first-child+td {
    width: 5.67em;
}
.externalModal tbody tr.thead th:before {
    content: '\a0';
    display: block;
    height: 1em;
    border-bottom: 1px solid #727372;
    margin: 0 -10px 10px;
}
.externalModal tbody tr.thead th:first-child:before {
    margin-left: -10px;
}


/***
    About pages
***/

body.about h1 {
    margin-top: 40px;
}

.map {
    position: relative;
    min-height: 10em;
    margin: 0 0 .875em;
}
.map .copyright {
    position: absolute;
    right: 30px;
    bottom: 30px;
    margin: 0 0 -.2em;
    font-family: "Trebuchet MS", Helvetica, Arial, sans-serif;
    text-shadow: -3px -3px #e9e5db, -3px 3px #e9e5db, 3px -3px #e9e5db, 3px 3px #e9e5db,
    0 -3px #e9e5db, 0 3px #e9e5db, -3px 0 #e9e5db, 3px 0 #e9e5db;
    cursor: default;
    font-size: .778em;
}
.about .widerCol1 .map~p {
    margin: .15em 0;
}

body.about .wideSect.fullWidthImg {
    background: #3d3d3d;
}
.fullWidthImg .contentWrap:first-child {
    margin: 0 auto;
    position: relative;
    float: none;
}
body.about .fullWidthImg .contentWrap:first-child {
    max-width: 1420px;
}
.fullWidthImg .contentWrap img,
.fullWidthImgWrap img {
    display: block;
    margin: 0 auto;
}
.fullWidthImg .contentWrap.caption,
.fullWidthImgCaption {
    background: #f8f8f8;
    padding-top: 1px;
    font-size: .778em;
}
.fullWidthImgWrap {
    width: 100%;
    float: right;
    right: 50%;
    position: relative;
}
.fullWidthImgWrap {
    width: 100vw;
}
.fullWidthImgWrap img {
    position: relative;
    right: -50%;
}
.fullWidthImgWrap img {
    right: -50vw;
}



/***
    Blog pages
***/

body.blog .blogEntries .entryWrap {
    margin-bottom: 60px;
}

.blogHeader,
.blog .section.blogFooter {
    margin-bottom: 1.67em;
}
body.blog .entryText .blogHeader {
    margin-bottom: 0;
}
body.blog h1 {
    margin: 0 0 4px;
}
.blogHeader p.date {
    margin: 0 0 1em;
}
body.blog .entryText .blogHeader p {
    margin: 0 0 .5em;
}

body.blog .entryText p.lead-in {
    margin-top: 0;
}

.blogCategories,
.blog .categories,
.blogFooter h3 {
    color: #666;
}

body.blog h2 {
    margin: 1em 0 .5em;
}


/***
    Footer
***/

#footerWrapper {
    background: #212121;
    color: #fff;
    clear: both;
    z-index: 9;
}
#footer h2,
#footer .contentWrap {
    max-width: 1170px;
    margin: 0 auto;
    background: transparent;
}
#footer h2,
#footer h3 {
    margin: 0 auto 58px;
    line-height: 1;
    color: rgba(255,255,255,.8);
}
#footer h3 {
    margin: 0;
}
#footer p {
    margin: .5em 0 1em;
    line-height: 2.125;
}
#footer a {
    color: #d3d3d3;
    color: rgba(255,255,255,.8);
    display: block;
}
#footer a i.fa {
    color: #d3d3d3;
    color: rgba(255,255,255,.8);
}
#footerWrapper a:hover,
#footerWrapper a:focus,
#footerWrapper a:active {
    color: #fff;
}
#footer p a {
    display: inline;
    display: inline-block;
    min-height: 1em;
    margin: 0;
}

#footer .section {
    padding: 88px 20px 30px;
}
#footer .section.footerProducts {
    padding: 88px 20px 65px;
}
#footer>.section:first-child,
#footer footer>.section:first-child {
    background: #312a26 url(../images/footer-bg.jpg) repeat-x 0 0;
}
#footer .col {
    font-size: .889em;
    clear: both;
    float: left;
    width: 100%;
    padding-top: .33em;
    margin-top: 24px;
}
#footer .footerProducts .col {
    margin-top: 0;
    padding: 2px 0;
    border-bottom: 1px solid #6d5e53;
}
.footerProducts .col:first-child {
    border-top: 1px solid #6d5e53;
}
#footer p:last-child {
    margin-bottom: 0;
}

#footer .nav a,
#footer .social a {
    padding: .33em 0;
}
#footer .footerProducts h3 a {
    padding: .67em 0;
}
#footer .footerProducts i.arrow {
    font-size: .778em;
    float: right;
    margin: .2em 0 0;
}
.footerProducts ul.nav {
    margin-bottom: .5em;
}
#footer .doorStyles ul.nav a {
    margin-right: .75em;
}
#footer .footerProducts ul.nav i.fa {
    min-width: .875em;
}

.footerProducts ul.nav ul {
    margin-left: .875em;
}


.footerProducts .list2ColsWrap {
    /* the parent of the list and immediately preceeding floated element must be
       floated or absolutely positioned or else the section disappears in
       Firefox 1.0 and Opera 7.2-8.x. */
    float: left;
    width: 100%;
    margin: -1em 0 1em;
}
.footerProducts .list2ColsWrap .listColShim {
    float: left;
    width: 100%;
    height: 1em;
}
.footerProducts .list2ColsWrap li {
    /* setting a height in this rule will break this in Opera */
    position: relative;
    display: block;
    float: none;
    width: 50%;
}
.footerProducts .list2ColsWrap li li {
    width: auto;
    display: list-item;
}
.footerProducts .list2ColsWrap li.col2 {
    margin-left: 50%;
}
.footerProducts .list2ColsWrap li.col1:first-child,
.footerProducts .list2ColsWrap .col1+li.col2 {
    margin-top: -9999px;
    clear: left;
}
.footerProducts .list2ColsWrap li.col1:first-child:before,
.footerProducts .list2ColsWrap .col1+li.col2:before {
    /* this fixes disappearing items in Firefox 1.0 and Opera */
    clear: left;
    display: block;
    content: ".";
    height: 0;
    visibility: hidden;
}

@media all and (min-width:640px) {
    #footer .footerProducts .col {
        width: 14.5%;
        min-width: 157px;
        display: inline;
        display: inline-block;
        padding: 0 2.6% 0 0;
        vertical-align: top;
        clear: none;
        float: none;
        border: 0 none;
        margin-top: 0;
        margin-bottom: 24px;
    }
}

#footer .sitemap h3 {
    margin-bottom: .75em;
}
#footer .sitemap li {
    padding: 2px 0;
    border-bottom: 1px solid #3d3d3d;
}
#footer .sitemap li:first-child {
    border-top: 1px solid #3d3d3d;
}
#footer .sitemap a {
    padding: .6em 0;
}
.sitemap .backToTop i.fa-chevron-up {
    float: right;
    position: relative;
    top: .25em;
}

#footer .tools a {
    padding-left: 1.375em;
}
#footer .tools i.fa {
    margin: .12em 0 0 -1.375em;
    float: left;
}

#footer .map i.fa {
    margin-right: .3em;
}

#footer .tools h3,
#footer .social h3 {
    margin-bottom: .33em;
}
#footer .social i.fa {
    width: 1.875em;
}

#subFooter {
    padding: 56px 20px 53px;
    text-align: center;
    font-size: .778em;
    background: #121212;
    clear: both;
    color: rgba(255,255,255,.6);
}
#subFooter p {
    margin: 0 auto;
    max-width: 1170px;
}
#subFooter a {
    color: #fff;
    color: rgba(255,255,255,.6);
}
#subFooter:hover,
#subFooter:hover a,
#subFooter.tapped,
#subFooter.tapped a,
#footer a:focus,
#footer a:active {
    color: #fff;
}


/*
    Colorbox Core Style:
    The following CSS is consistent between example themes and should not be altered.
*/
#colorbox, #cboxOverlay, #cboxWrapper{position:absolute; top:0; left:0; z-index:9999; overflow:hidden;}
#cboxOverlay{position:fixed; width:100%; height:100%;}
#cboxMiddleLeft, #cboxBottomLeft{clear:left;}
#cboxContent{position:relative;}
#cboxLoadedContent{overflow:auto; -webkit-overflow-scrolling: touch;}
#cboxTitle{margin:0;}
#cboxLoadingOverlay, #cboxLoadingGraphic{position:absolute; top:0; left:0; width:100%; height:100%;}
#cboxPrevious, #cboxNext, #cboxClose, #cboxSlideshow{cursor:pointer;}
.cboxPhoto{float:left; margin:auto; border:0; display:block; max-width:none; -ms-interpolation-mode:bicubic;}
.cboxIframe{width:100%; height:100%; display:block; border:0; margin:0; padding:0;}
#colorbox, #cboxContent, #cboxLoadedContent, #cboxClose{-moz-box-sizing:content-box; -webkit-box-sizing:content-box; box-sizing:content-box;}

/*
    User Style:
    Change the following styles to modify the appearance of Colorbox.  They are
    ordered & tabbed in a way that represents the nesting of the generated HTML.
*/
#cboxOverlay{background:#3d3d3d; opacity: 0.9; filter: alpha(opacity=90);}
#colorbox{outline:0;padding:15px 15px 15px 0;}
#cboxWrapper{background:#3d3d3d; border: 1px solid #727372; margin:15px 15px 15px 0; overflow:visible; -moz-border-radius: 10px; -webkit-border-radius: 10px; border-radius: 10px;}
#cboxTopLeft,#cboxTopRight,#cboxBottomLeft,#cboxBottomRight{ height:30px; width: 30px;}
#cboxMiddleLeft,#cboxMiddleRight {width:30px}
#cboxTopCenter,#cboxBottomCenter { height:30px}
.cboxIframe,#cboxLoadingOverlay{background:#3d3d3d}
#cboxError{padding:50px; border:1px solid #ccc;}
#cboxContent {color:#fff}
#cboxContent #pageWrapper,
#cboxContent #mainContent {
    color: #fff;
    background: transparent;
}
#colorbox.varHeight,.varHeight>#cboxWrapper,.varHeight[id=colorbox] #cboxContent {
    -webkit-transition: height .5s;
    -moz-transition: height .5s;
    transition: height .5s;
}
/*
#cboxTitle{position:absolute; bottom:4px; left:0; text-align:center; width:100%; color:#949494;}
*/
#cboxCurrent{width:0;height:0;overflow:hidden}
/*#cboxLoadingGraphic{background:url(../images/loading.gif) no-repeat center center;}*/

/* these elements are buttons, and may need to have additional styles reset to avoid unwanted base styles */
#cboxPrevious, #cboxNext, #cboxSlideshow, #cboxClose {border:0; padding:0; margin:0; overflow:visible; width:auto; background:none; }

/* avoid outlines on :active (mouseclick), but preserve outlines on :focus (tabbed navigating) */
#cboxPrevious:active, #cboxNext:active, #cboxSlideshow:active, #cboxClose:active {outline:0;}
/*
#cboxSlideshow{position:absolute; bottom:4px; right:30px; color:#0092ef;}
*/
#cboxNext, #cboxPrevious {
    position:absolute;
    top:150px;
    right: 0;
    width:4em;
    height:4em;
    opacity: 0;
    -moz-transition: opacity .5s;
    -webkit-transition: opacity .5s;
    -o-transition: opacity .5s;
    transition: opacity .5s;
}
#cboxPrevious{left:0;}
#cboxNext:hover, #cboxPrevious:hover, #cboxNext:focus, #cboxPrevious:focus {
    opacity: 1;
}
#cboxClose{
    position:absolute;
    top: -42px;
    right: -42px;
    display: block;
    width: 27px;
    height: 27px;
    text-indent: -9999px;
    background: url(../images/close-icon.png) no-repeat 0 0;
    background-image: -webkit-linear-gradient(transparent,transparent), url(../images/close-icon.svg);
    background-image: linear-gradient(transparent,transparent), url(../images/close-icon.svg);
    -moz-background-size: 100% 100%;
    -webkit-background-size: 100% 100%;
    background-size: 100% 100%;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}
#cboxClose:hover{
    -webkit-box-shadow: 0 0 2px 2px rgba(102,102,255,.7);
    -moz-box-shadow: 0 0 2px 2px rgba(102,102,255,.7);
    box-shadow: 0 0 2px 2px rgba(102,102,255,.7);
}
.videoBox #cboxClose {
    top: -27px;
}

/* HTML5 Number polyfill | Jonathan Stipe | https://github.com/jonstipe/number-polyfill*/
div.number-spin-btn-container {
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  margin: 0;
  padding: 0; }

div.number-spin-btn {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-width: 2px;
  border-color: #ededed #777 #777 #ededed;
  border-style: solid;
  background-color: #ccc;
  width: 1.2em; }
  div.number-spin-btn:hover {
    cursor: pointer; }
  div.number-spin-btn:active {
    border-width: 2px;
    border-color: #5e5e5e #d8d8d8 #d8d8d8 #5e5e5e;
    border-style: solid;
    background-color: #999; }

div.number-spin-btn-up {
  border-bottom-width: 1px;
  -moz-border-radius: 3px 3px 0 0;
  -webkit-border-radius: 3px 3px 0 0;
  border-radius: 3px 3px 0 0; }
  div.number-spin-btn-up:before {
    border-width: 0 .3em .3em;
    border-color: transparent transparent #000;
    top: 25%; }
  div.number-spin-btn-up:active {
    border-bottom-width: 1px; }
  div.number-spin-btn-up:active:before {
    border-bottom-color: white;
    top: 26%;
    left: 51%; }

div.number-spin-btn-down {
  border-top-width: 1px;
  -moz-border-radius: 0 0 3px 3px;
  -webkit-border-radius: 0 0 3px 3px;
  border-radius: 0 0 3px 3px; }
  div.number-spin-btn-down:before {
    border-width: .3em .3em 0;
    border-color: #000 transparent transparent;
    top: 75%; }
  div.number-spin-btn-down:active {
    border-top-width: 1px; }
  div.number-spin-btn-down:active:before {
    border-top-color: white;
    top: 76%;
    left: 51%; }

div.number-spin-btn-up:before,
div.number-spin-btn-down:before {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  left: 50%;
  margin: -0.15em 0 0 -0.3em;
  padding: 0; }

input:disabled + div.number-spin-btn-container > div.number-spin-btn-up:active, input:disabled + div.number-spin-btn-container > div.number-spin-btn-down:active {
  border-color: #ededed #777 #777 #ededed;
  border-style: solid;
  background-color: #cccccc; }
input:disabled + div.number-spin-btn-container > div.number-spin-btn-up:before, input:disabled + div.number-spin-btn-container > div.number-spin-btn-up:active:before {
  border-bottom-color: #999;
  top: 25%;
  left: 50%; }
input:disabled + div.number-spin-btn-container > div.number-spin-btn-down:before, input:disabled + div.number-spin-btn-container > div.number-spin-btn-down:active:before {
  border-top-color: #999;
  top: 75%;
  left: 50%; }

