/* Slider */
.slick-slider { position: relative; display: block; box-sizing: border-box; -moz-box-sizing: border-box; -webkit-touch-callout: none; -webkit-user-select: none; -khtml-user-select: none; -moz-user-select: none; -ms-user-select: none; user-select: none; -ms-touch-action: pan-y; touch-action: pan-y; -webkit-tap-highlight-color: transparent; }

.slick-list { position: relative; overflow: hidden; display: block; margin: 0; padding: 0; }
.slick-list:focus { outline: none; }
.slick-loading .slick-list { background: white url("../images/ajax-loader.gif") 50% no-repeat; }
.slick-list.dragging { cursor: pointer; }

.slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img { -webkit-transform: translate3d(0, 0, 0); -moz-transform: translate3d(0, 0, 0); -ms-transform: translate3d(0, 0, 0); -o-transform: translate3d(0, 0, 0); transform: translate3d(0, 0, 0); }

.slick-track { position: relative; left: 0; top: 0; display: block; }
.slick-track:before, .slick-track:after { content: ""; display: table; }
.slick-track:after { clear: both; }
.slick-loading .slick-track { visibility: hidden; }

.slick-slide { float: left; height: 100%; min-height: 1px; display: none; }
.slick-slide img { display: block; }
.slick-slide.slick-loading img { display: none; }
.slick-slide.dragging img { pointer-events: none; }
.slick-initialized .slick-slide { display: block; }
.slick-loading .slick-slide { visibility: hidden; }
.slick-vertical .slick-slide { display: block; height: auto; border: 1px solid transparent; }

/* Arrows */
.slick-prev, .slick-next { position: absolute; display: block; height: 100px; width: 40px; font-size: 0; cursor: pointer; background: transparent; color: transparent; top: 50%; margin-top: -50px; padding: 0; border: none; outline: none; opacity: 0.5; }
.slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus { outline: none; opacity: 1; }
.slick-prev.slick-disabled, .slick-next.slick-disabled { opacity: 0.25; }

.slick-prev, .slick-next { content: ""; display: block; width: 20px; height: 60px; background: no-repeat center center; }
.slick-prev { left: 35px;  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='60' viewBox='0 0 20 60'><title>Previous</title><path fill='#FFF' d='M20.993 18.214q0 0.435-0.335 0.77l-13.158 13.158 13.158 13.158q0.335 0.335 0.335 0.77t-0.335 0.77l-1.674 1.674q-0.335 0.335-0.77 0.335t-0.77-0.335l-15.603-15.603q-0.335-0.335-0.335-0.77t0.335-0.77l15.603-15.603q0.335-0.335 0.77-0.335t0.77 0.335l1.674 1.674q0.335 0.335 0.335 0.77z'></path></svg>"); }
.slick-next { right: 35px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='20' height='60' viewBox='0 0 20 60'><title>Next</title><path fill='#FFF' d='M19.922 32.143q0 0.435-0.335 0.77l-15.603 15.603q-0.335 0.335-0.77 0.335t-0.77-0.335l-1.674-1.674q-0.335-0.335-0.335-0.77t0.335-0.77l13.158-13.158-13.158-13.158q-0.335-0.335-0.335-0.77t0.335-0.77l1.674-1.674q0.335-0.335 0.77-0.335t0.77 0.335l15.603 15.603q0.335 0.335 0.335 0.77z'></path></svg>"); }


/* Dots */
.slick-slider { margin-bottom: 30px; }

.slick-dots { position: absolute; bottom: -45px; list-style: none; display: block; text-align: center; padding: 0; width: 100%; }

body.home .gallery .slick-dots:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
}
body.home .gallery .slick-dots a {
    cursor: default;
}

.slick-dots li { position: relative; display: inline-block; height: 20px; width: 20px; margin: 0; padding: 0; cursor: pointer; }
.slick-dots li button { border: 0; background: transparent; display: block; height: 20px; width: 14px; outline: none; line-height: 0; font-size: 0; color: transparent; padding: 5px 1px; cursor: pointer; }
.slick-dots li button:hover, .slick-dots li button:focus { outline: none; }
.slick-dots li button:hover .dot, .slick-dots li button:focus .dot { background: #fff; }
.slick-dots button .dot {
    display: block;
    width: 6px;
    height: 6px;
    text-align: center;
    background: #84634c;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: background .5s;
    -moz-transition: background .5s;
    transition: background .5s;
    -webkit-box-shadow: inset 0 1px rgba(0,0,0,.5), 0 1px rgba(255,255,255,.3);
    -moz-box-shadow: inset 0 1px rgba(0,0,0,.5), 0 1px rgba(255,255,255,.3);
    box-shadow: inset 0 1px rgba(0,0,0,.5), 0 1px rgba(255,255,255,.3);
}
.slick-dots li.slick-active button .dot { background: #fff; -webkit-box-shadow: none; -moz-box-shadow: none; box-shadow: none; }
