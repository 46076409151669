/* Generated by Font Squirrel (http://www.fontsquirrel.com) on July 25, 2014 */

@font-face {
    font-family: 'geared_slab';
    src: url('/assets/fonts/gearedslab-light.eot');
    src: url('/assets/fonts/gearedslab-light.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/gearedslab-light.woff') format('woff'),
         url('/assets/fonts/gearedslab-light.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'geared_slab';
    src: url('/assets/fonts/gearedslab-extrabold.eot');
    src: url('/assets/fonts/gearedslab-extrabold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/gearedslab-extrabold.woff') format('woff'),
         url('/assets/fonts/gearedslab-extrabold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'geared_slab';
    src: url('/assets/fonts/gearedslab.eot');
    src: url('/assets/fonts/gearedslab.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/gearedslab.woff') format('woff'),
         url('/assets/fonts/gearedslab.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'geared_slab';
    src: url('/assets/fonts/gearedslab-bold.eot');
    src: url('/assets/fonts/gearedslab-bold.eot?#iefix') format('embedded-opentype'),
         url('/assets/fonts/gearedslab-bold.woff') format('woff'),
         url('/assets/fonts/gearedslab-bold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
