ul.cb {
    display: none;
}

div#numfound {
    display: none;
}

div.fieldset {
    display: none;
}

div.fieldset span.inactive {
    color: #999999;
}

span.countBadge:empty {
    display: none;
}

a.nextPage {
    display: none;
}

div.jscroll-inner,
div.jscroll-added {
    margin-right: -15px;
}

span.lighter {
    color: #666666;
}