/* CSS3 Document */

#pageWrapper .hideFromMobile,
#header .nav li {
    display: block;
}
.mobileOnly,
.sitemap li.mobileOnly {
    display: none;
}
/*
@media all and (max-width:660px) {
    .mobileOnly, #header div.mobileMenuBtn, .sitemap li.mobileOnly {
        display: inline-block;
    }
}*/

.button.mobileBlock {
    display: inline-block;
    clear: none;
    width: 9.05em;
    max-width: 100%;
}
.button.wider {
    min-width: 9.05em;
    width: auto;
}

blockquote.bqCenter {
    text-align: center;
    margin: 1.5em 8.5%;
}
blockquote.bqLeft {
    float: left;
    width: 31.62%;
    margin: 0 2.56% 0.5em 0;
}
blockquote.bqRight {
    float: right;
    width: 31.62%;
    margin: 0 0 0.5em 2.56%;
}
blockquote.bqLeft p:first-child,
blockquote.bqRight p:first-child {
    margin-top: 0;
}
blockquote.bqLeft p:last-child,
blockquote.bqRight p:last-child {
    margin-bottom: 0;
}

.imgWrap img {
    vertical-align: top;
}
.imgWrap.imgLeft {
    float: left;
    width: 57.26%;
    margin: 0 2.56% 1em 0;
}
.imgWrap.imgRight {
    float: right;
    width: 57.26%;
    margin: 0 0 1em 2.56%;
}
.imgWrap + p {
    margin-top: 0;
}

/***
    Header and Main Navigation
***/

#header li.search {
    display: none;
}
/*
#header {
    padding-left: 164px;
}*/
#logoWrapper {
    margin-top: 5px;
    /*
    position: absolute;
    top: 0;
    left: 10px;*/
}

@media all and (min-width: 1170px) {
    #headerWrapper {
        background: rgba(0, 0, 0, 0.8);
        min-height: 100px;
    }
    #header {
        padding-top: 0;
    }
    #logoWrapper {
        margin-top: 15px;
    }
}
#logo,
#logo object,
#logo img,
#logo .link {
    width: 154px;
    height: auto;
    min-height: 40px;
    max-height: 56px;
}
#header .nav .widestOnly {
    display: none;
}
@media all and (min-width: 1170px) {
    #header div.mobileMenuBtn {
        display: none;
    }
    #header .menu {
        clear: none;
        display: inline;
        margin: 0;
        position: static;
    }
    #header ul.nav {
        float: right;
        margin: 0 10px 0 0;
        padding: 0;
        border: 0 none;
    }
    #header ul.nav li {
        float: left;
        position: relative;
        z-index: 99;
        padding: 10px 0;
        border: 0 none;
    }
    #header li.search {
        display: block;
    }
    #header ul.nav li li {
        display: block;
        float: none;
        padding: 0;
    }
    #header ul.nav a {
        padding: 0.917em 0.75em;
        border-left: 1px solid rgba(229, 229, 229, 0.2);
    }
    #header ul.nav li.search a {
        font-size: 0.75em;
        padding: 1.47em 1.14em 1.47em 1.7em;
    }
}
@media all and (min-width: 1190px) {
    #header ul.nav {
        margin: 0 20px 0 0;
    }
}
@media all and (min-width: 1250px) {
    #header .nav .widestOnly {
        display: inline;
    }
}
@media all and (min-width: 1300px) {
    #header ul.nav a {
        padding: 0.917em 1em;
    }
}
@media all and (min-width: 1170px) {
    #header ul.nav li:first-child a {
        border-left: 0 none;
    }
    #header ul.nav a:hover,
    #header ul.nav a:active,
    #header ul.nav li.current > a,
    #header ul.nav a:focus,
    #header ul.nav li:hover > a,
    #header ul.nav li.open > a {
        color: #fff;
        background: transparent;
    }
    #header ul.nav ul a {
        font-size: 1em;
        padding: 9px 25px 9px 30px;
        border-left: 0 none;
    }
    ul.nav .fa.arrow {
        vertical-align: 0.1em;
        float: none;
        margin-top: 0;
    }
    #header ul.nav li.jsHovers ul,
    #header ul.nav li.jsHovers .dropdown {
        overflow: hidden;
    }
    #header ul.nav li.jsHovers:hover ul,
    #header ul.nav li.jsHovers:hover .dropdown {
        position: absolute;
        left: -9999px;
        top: -4999px;
    }
    #header ul.nav li:hover ul,
    #header ul.nav li.open ul,
    #header ul.nav li:hover .dropdown,
    #header ul.nav li.open .dropdown,
    #header ul.nav li.open:hover ul,
    #header ul.nav li.open:hover .dropdown {
        position: absolute;
        left: auto;
        right: 0;
        top: 100%;
        background: #000;
        background: rgba(0, 0, 0, 0.8);
        min-width: 12em;
        width: 100%;
        border-top: 1px solid rgba(9, 9, 9, 0.1);
        background-clip: padding-box;
    }
    #header ul.nav li:hover .dropdown,
    #header ul.nav li.open .dropdown,
    #header ul.nav li.open:hover .dropdown {
        min-width: 100%;
        width: 280px;
    }

    #header .searchForm {
        position: absolute;
        top: auto;
        bottom: 100%;
        right: 0;
        margin: 0;
        background: #000;
        border-top: 0 none;
        border-bottom: 1px solid #616161;
        border-color: rgba(58, 58, 58, 0.8);
        background-clip: padding-box;
        -webkit-transition: bottom 0.5s;
        -moz-transition: bottom 0.5s;
        transition: bottom 0.5s;
        padding: 0.5em 1em;
        text-align: right;
    }
    #searchbar:target {
        bottom: -4.95em;
    }
    #header .searchForm input {
        padding: 0.375em 1em;
        font-size: 1.33em;
        -webkit-border-radius: 8px;
        -moz-border-radius: 8px;
        border-radius: 8px;
        width: 360px;
    }
    #header .searchForm button {
        padding: 0.5em 0.786em;
    }
    #header .searchForm a.close {
        display: inline-block;
        color: #999;
        padding: 22px;
        border-left: 1px solid #292929;
        margin: 0 9px 0 25px;
        vertical-align: middle;
    }
}

@media screen and (min-width: 78em) {
    /* 1250px */
    #header .searchForm {
        bottom: 5.56em;
    }
}

#header .loginForm input {
    /* see modal styles */
    width: 278px;
}

/***
    Main Content Styles
***/

.contentWrap .section,
.breadcrumbsInner,
body.home .tablet4x4 .section,
.productFilters.section .contentWrap {
    max-width: 1170px;
    padding: 0 10px;
    margin: 0 auto;
}
.productsHeader,
.sectionHeader {
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    padding: 4px 20px 5px;
}
.productFilters.section,
.section.wideSect {
    max-width: none;
    padding: 0;
}
.contentWrap.oneSect .section {
    max-width: 1170px;
    padding: 0 20px;
    margin: 0 auto;
}
.contentWrap.tabContent .section {
    padding: 90px 20px 65px;
}
.contentWrap.tabContent.fourCols .section {
    padding: 60px 16px 85px;
}

.contentWrap {
    float: none;
    clear: both;
    width: auto;
}
.contentWrap.oneSect {
    max-width: none;
    float: left;
    width: 100%;
    padding: 0;
}
.contentWrap .contentWrap {
    padding: 0;
}

.contentWrap .contentWrap.threeCols {
    position: relative;
}

.fourCols .col,
.threeCols .col,
.twoCols .col {
    float: left;
    width: 33.33%;
}
.fourCols .col,
body.home .tablet4x4 .col {
    width: 22.4%;
    margin-right: 2.55%;
}
#photo-gallery.fourCols .col {
    max-width: 200px;
}
.productFilters .threeCols .col {
    width: 30.76%;
    margin-right: 2.57%;
}
body.home .tablet4x4 .col {
    margin-left: 0;
}
.fourCols.oneSect .col {
    margin-left: 1.28%;
    margin-right: 1.28%;
}
.twoCols .col {
    width: 48.71%;
    margin-left: 1.28%;
}
.twoCols .col:first-child {
    margin-left: 0;
    margin-right: 1.28%;
}
.productSpecs + .twoCols .col:only-child {
    margin-left: 19.18%;
    margin-right: 0;
    width: 61.64%;
}
.twoCols.widerCol1 .col + .col {
    width: 40.17%;
}
.twoCols.widerCol1 .col:first-child {
    width: 57.25%;
}

.threeCols .doubleCol {
    width: 66.66%;
}
body.home .threeCols .doubleCol {
    width: 64.55%;
    margin-right: 2%;
}
body.about .threeCols .doubleCol {
    float: right;
}
.contentWrap.fourCols,
.contentWrap.threeCols,
.contentWrap.twoCols {
    margin-bottom: 2em;
}
.contentWrap.threeCols:last-child,
.contentWrap.twoCols:last-child {
    margin-bottom: 2em;
}
.threeCols .contentWrap.twoCols,
.twoCols .contentWrap.twoCols {
    margin-bottom: 1em;
}
.contentWrap.tablet4x4 {
    margin-bottom: 0;
}

.contentWrap.tabContent {
    margin-bottom: 0;
}
.contentWrap.tabContent .contentWrap.twoCols:last-child {
    margin-bottom: 0;
}

.contentWrap.grid4x4 {
    margin: 0 -10px 0 0;
    left: 0;
    max-width: none;
}
.grid4x4 .col {
    width: 45.66%;
    margin: 0 2.16% 2.66%;
}
@media all and (min-width: 960px) {
    .grid4x4 .col {
        width: 42.66%;
        margin: 0 3.66% 3.66%;
    }
}
@media all and (max-width: 890px) {
    body.home .threeCols .doubleCol {
        width: 100%;
        margin: 0;
    }
    body.home .threeCols .doubleCol + .col {
        width: 100%;
        margin: 0;
    }
    .grid4x4 .col {
        /*
        width: 22.42%;
        margin: 1.29%;
        */
        width: 22.68%;
        margin: 1%;
        float: none;
    }
    body.home .grid4x4 {
        word-spacing: -0.27em;
        margin: 0 0 6px;
    }
    body.home .grid4x4:not(:-webkit-any-link) {
        letter-spacing: 0;
    }
}
@media all and (max-width: 680px) {
    body.home .grid4x4 {
        max-width: 574px;
        margin: 0 auto;
    }
    .grid4x4 .col {
        width: 44.83%;
        margin: 2%;
    }
}

/***
    Home Page
***/

body.home #mainContent .contentWrap.gallery {
    padding: 54px 0 0;
}
body.home .section.gallery {
    padding-top: 31px;
    max-width: none;
}

body.home .gallery p {
    font-size: 1.73em;
    line-height: 1;
    margin: 0 20px;
}
body.home .gallery p.large {
    font-size: 5em;
    margin-bottom: -15px;
}

@media all and (min-width: 1420px) {
    body.home .section.gallery {
        padding-top: 89px;
    }
    body.home .gallery p {
        font-size: 2em;
    }
    body.home .gallery p.large {
        font-size: 6em;
        margin-bottom: 10px;
    }
}
@media all and (max-width: 780px) {
    body.home .gallery p {
        font-size: 1.33em;
    }
    body.home .gallery p.large {
        font-size: 3.22em;
    }
}

body.home .gallery .buttons {
    margin: 0 0 26px;
}
body.home .gallery .buttons .button {
    bottom: -1.167em;
    width: 12.5em;
    padding: 13px 8px 12px;
}

body.home .galleryBg {
    top: -100px;
    height: 1200px;
}
.galleryBg img {
    left: 0;
    right: 0;
    min-height: 598px;
}

@media screen and (min-width: 1750px) {
    body.home .carouselWrap {
        -webkit-background-size: 100% auto;
        -moz-background-size: 100%;
        background-size: 100%;
    }
}
.carouselWrap .slick-arrows {
    margin: 0 auto;
    max-width: 1200px;
    width: 100%; /* fix for IE11 */
}

.slick-dots li {
    margin: 0 5px;
}
.slick-dots li button {
    height: 20px;
    width: 20px;
    padding: 5px;
}

body.home #mainContent .contentWrap.threeCols {
    padding: 83px 0 40px;
}

body.home #mainContent .contentWrap.fourCols {
    padding: 101px 0 48px;
}

@media screen and (min-resolution: 144dpi),
    (-webkit-min-device-pixel-ratio: 1.5),
    (min--moz-device-pixel-ratio: 1.5) {
    body.home .grid4x4 img {
        background-image: url(../images/homepage-about-sprite@2x.jpg);
    }
    body.home .fourCols img {
        background-image: url(../images/homepage-tile-sprite@2x.jpg);
    }
}

body.home .tablet4x4 .section {
    position: relative;
}
body.home .tablet4x4 .colWrap {
    float: none;
    width: auto;
    position: static;
}
body.home .tablet4x4 .col {
    padding: 0 0 2.22em;
}
body.home .contentWrap .btnMenu,
body.home .contentWrap p.btnWrap {
    bottom: 42px;
    position: absolute;
    width: 22.4%;
    margin: 0;
}

/***
    Product Listings
***/

.contentWrap.products {
    background-attachment: fixed;
}
.productsHeader {
    padding-top: 0;
}
.productsHeader h1,
.productsHeader h2 {
    margin: 0 3.6em 3px 0;
}
.productsHeader .showFilters {
    position: absolute;
    padding: 0;
    right: 20px;
    bottom: 7px;
}
.productsHeader .showFilters.pointer:before,
.productsHeader .showFilters.pointer:after {
    left: 10px;
    right: 0;
    margin: auto;
    bottom: -8px;
}
.productsHeader .showFilters a {
    display: block;
    padding: 10px 0 10px 10px;
}
.productFilters.section {
    padding: 60px 10px;
}
.productFilters.section .contentWrap.fourCols {
    margin: 0 auto;
}

.resultsInfo .selectedFilters {
    float: left;
}

.resultsInfo .resultsNum {
    float: right;
    clear: none;
}
.productsListing {
    margin: 0 -15px;
}

.productsListing .productBox {
    font-size: 0.875em;
    width: 22.43%;
    padding: 0 1.28% 1.92%;
    display: inline-block;
    vertical-align: bottom;
    text-align: left;
}

/***
    Product Details
***/

body.products.details #mainPhoto,
.productPhotoCol .twoCols {
    margin: 0 8.77%;
}
body.molding-veneers.details .productPhoto img {
    width: 100%;
}

body.details .productFilters .col.drawer {
    float: none;
    display: inline-block;
    vertical-align: bottom;
}
body.products.details .productFilters .drawer label {
    width: 100%;
}
body.products.details .productFilters .drawer .name {
    margin-bottom: 20px;
}
body.products.details .productFilters .imgWrap {
    padding-top: 56.1%;
    height: 0;
    position: relative;
    display: block;
}
body.products.details .productFilters .imgWrap img {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
}

body.products #mainPhoto.drawer {
    height: 600px;
}
#mainPhoto.drawer .drawerPhotoWrap,
body.products #mainPhoto.drawer:after {
    display: inline;
    display: inline-block;
    vertical-align: middle;
    max-width: 100%;
    margin-right: -0.4em;
}
body.products #mainPhoto.drawer:after {
    content: '\a0';
    height: 100%;
    width: 1px;
    margin: 0 0 0 -0.4em;
}

h2.productSpecs,
p.productSpecs {
    text-align: center;
}
p.productSpecs {
    margin: 0 0 1em;
}

#calcDoorSize th,
#calcDoorSize td {
    border-top: 1px solid #727372;
    padding: 6px 0 5px 15px;
    display: table-cell;
}
#calcDoorSize th {
    border: 1px solid #727372;
    border-left: 0 none;
    width: 10.67em;
    padding: 6px 15px 5px 0;
}

#calcPrice .col {
    width: 60%;
    margin: 0;
}
#calcPrice .col + .col {
    width: 40%;
}
#calcPrice .col .col {
    width: 48.3%;
}
#calcPrice .col .col + .col {
    width: 51.7%;
}
#calcPrice select {
    max-width: 370px;
    width: 95%;
}
#priceWidth,
#priceHeight {
    max-width: 170px;
    width: 90%;
}
#calcPrice .label {
    width: auto;
}
#calcPrice .borders {
    display: block;
    width: auto;
    margin-bottom: 0;
}
.modalContent .col:first-child .borders,
.modalContent .col .col:first-child .borders,
.modalContent .col .col:first-child label {
    border-left: 0 none;
    border-right: 0 none;
    padding-left: 0;
}
.modalContent .col .borders,
.modalContent .col .col .borders {
    border: 1px solid #727372;
    border-right: 0 none;
    display: block;
    padding: 6px 0 6px 14px;
    min-height: 39px;
    margin-top: 10px;
}
.modalContent .col label {
    padding-left: 15px;
}
#priceSqFt {
    margin: 1px 0 0;
}

.contentWrap .section.share {
    padding: 30px 20px 24px;
}
.share ul.social {
    margin: 0;
}
.share ul.social li {
    margin: 0 0.5em;
}

.availableOptions .sectionHeader {
    text-align: center;
    padding-top: 71px;
}
.availableOptions .sectionHeader p {
    margin: 0.67em auto 1.83em;
    max-width: 764px;
}

.tabsContainer.swiper {
    margin-bottom: -13px;
}
.tabsContainer .prev,
.tabsContainer .next {
    margin-bottom: 12px;
}
ul.tabs li {
    margin: 10px 0.48em -8px 0;
    font-size: 1em;
    max-width: 9.67em;
}
ul.tabs a {
    padding: 0.556em 1.25em 0.5em;
    position: relative;
    top: -7px;
    margin-bottom: -8px;
}
ul.tabs a.selected {
    margin-bottom: 0;
    padding: 0.556em 1.25em;
    background: #b3b4b3;
}
ul.tabs .tabTxt,
.tabsContainer.swiper .tabTxt {
    display: inline-block;
    vertical-align: middle;
}
ul.tabs li a:after,
.tabsContainer.swiper .slideInner a:after {
    content: '.';
    height: 2.67em;
    display: inline-block;
    width: 1px;
    margin-left: -1px;
    visibility: hidden;
    vertical-align: middle;
}

body.products.details .relatedProducts {
    padding: 86px 0 81px;
}
body.products.details .relatedProducts .section {
    position: relative;
}
.carousel .swiper-slide {
    padding: 0 15px;
}
body.details .relatedProducts .showArrows .prev,
body.details .relatedProducts .showArrows .next {
    display: block;
    position: absolute;
    right: 15px;
    top: 0.835em;
    margin-top: 24px;
    width: 110px;
    padding: 0 30px;
    height: 146px;
    /*height: 244px;*/
    z-index: 99;
    opacity: 0.5;
    color: #fdfdfd;
    font-size: 2em;
    text-align: right;
    -webkit-transition: opacity 0.5s;
    -moz-transition: opacity 0.5s;
    transition: opacity 0.5s;
}
body.details .relatedProducts .prev {
    left: 15px;
    text-align: left;
}
.relatedProducts a.prev:hover,
.relatedProducts a.next:hover,
.relatedProducts a.prev:focus,
.relatedProducts a.next:focus,
.relatedProducts a.prev:active,
.relatedProducts a.next:active {
    opacity: 1;
}
body.details .relatedProducts .showArrows .prev:after,
body.details .relatedProducts .showArrows .next:after {
    content: '.';
    visibility: hidden;
    width: 1px;
    height: 100%;
    vertical-align: middle;
    display: inline-block;
}
body.details .relatedProducts .showArrows:before,
body.details .relatedProducts .showArrows:after {
    content: '';
    display: block;
    position: absolute;
    right: 0;
    top: 1.78em;
    margin-top: 24px;
    width: 185px; /* 170+15 */
    height: 145px; /* 143+2 */
    /*
    width: 195px;
    height: 236px;
    width: 201px;
    height: 243px;*/
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMjMyMjIxIiBzdG9wLW9wYWNpdHk9IjAiLz4NCjxzdG9wIG9mZnNldD0iMTAwJSIgc3RvcC1jb2xvcj0iIzIzMjIyMSIvPg0KPC9saW5lYXJHcmFkaWVudD4NCjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdWNnZykiIC8+DQo8L3N2Zz4=');
    background: -moz-linear-gradient(
        left,
        rgba(35, 34, 33, 0),
        rgba(35, 34, 33, 1)
    );
    background: -webkit-gradient(
        linear,
        0 0,
        100% 0,
        from(rgba(35, 34, 33, 0)),
        to(rgba(35, 34, 33, 1))
    );
    background: -p-linear-gradient(
        left,
        rgba(35, 34, 33, 0),
        rgba(35, 34, 33, 1)
    );
    background: linear-gradient(
        to right,
        rgba(35, 34, 33, 0),
        rgba(35, 34, 33, 1)
    );
    z-index: 95;
}
body.details .relatedProducts .showArrows:before {
    background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIHZpZXdCb3g9IjAgMCAxIDEiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiPg0KPGxpbmVhckdyYWRpZW50IGlkPSJ1Y2dnIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+DQo8c3RvcCBvZmZzZXQ9IjAlIiBzdG9wLWNvbG9yPSIjMjMyMjIxIi8+DQo8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMyMzIyMjEiIHN0b3Atb3BhY2l0eT0iMCIvPg0KPC9saW5lYXJHcmFkaWVudD4NCjxyZWN0IHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9InVybCgjdWNnZykiIC8+DQo8L3N2Zz4=');
    background: -moz-linear-gradient(
        left,
        rgba(35, 34, 33, 1),
        rgba(35, 34, 33, 0)
    );
    background: -webkit-gradient(
        linear,
        0 0,
        100% 0,
        from(rgba(35, 34, 33, 1)),
        to(rgba(35, 34, 33, 0))
    );
    background: -o-linear-gradient(
        left,
        rgba(35, 34, 33, 1),
        rgba(35, 34, 33, 0)
    );
    background: linear-gradient(
        to right,
        rgba(35, 34, 33, 1),
        rgba(35, 34, 33, 0)
    );
    left: 0;
}

/***
    Shipping Dates modal
***/

.externalModal.shippingDates {
    width: 770px;
}
.externalModal.shippingDates th,
.externalModal.shippingDates td {
    padding: 10px 30px;
}
.externalModal tbody th:first-child,
.externalModal thead th:first-child,
.externalModal tr.thead th:first-child {
    padding-left: 20px;
}
.externalModal tbody tr.thead th:before {
    margin: 0 -30px 10px;
}
.externalModal tbody tr.thead th:first-child:before {
    margin-left: -20px;
}

/***
    About pages
***/

body.about h1 {
    margin: 85px 0 34px;
}

.map .copyright {
    font-size: 1em;
}

@media screen and (min-width: 800px) and (min-height: 620px),
    screen and (min-width: 1400px) {
    .fullWidthImgWrap {
        width: -moz-calc(100vw - 20px);
        width: -webkit-calc(100vw - 20px);
        width: calc(100vw - 20px);
    }
    .fullWidthImgWrap img {
        right: -moz-calc(-50vw + 10px);
        right: -webkit-calc(-50vw + 10px);
        right: calc(-50vw + 10px);
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    /* set it back for iPads */
    .fullWidthImgWrap {
        width: 100vw;
    }
    .fullWidthImgWrap img {
        right: -50vw;
    }
}

/***
    Blog pages
***/

body.blog .blogEntries .entryWrap {
    margin-bottom: 90px;
}
body.blog .blogEntries .entryPhoto {
    float: left;
    max-width: 57.25%;
    margin-right: 2.55%;
}
body.blog .blogEntries .entryText {
    overflow: hidden;
    min-width: 40.17%;
}

body.blog .blogCategories {
    margin-top: 82px;
}

body.blog h1 {
    margin: 82px 0 4px;
}
body.blog .entryText h1 {
    margin-top: 0;
}

body.blog .wideSect.fullWidthImg {
    margin-top: 2em;
}

/***
    Footer
***/

#footer .section,
#footer .section.footerProducts {
    padding: 88px 20px 55px;
}

#footer h3,
#footer .tools h3,
#footer .social h3 {
    margin: 0 0 0.5em;
}
#footer .col,
#footer .footerProducts .col {
    width: 14.5%;
    min-width: 157px;
    display: inline;
    display: inline-block;
    padding: 0 2.6% 0 0;
    vertical-align: top;
    clear: none;
    float: none;
    border: 0 none;
    margin-top: 0;
    margin-bottom: 24px;
}
#footer .footerProducts .col {
    min-width: 196px;
    width: 18.25%;
}
#footer .footerProducts .col:first-child + .col ~ .col {
    min-width: 8em;
    max-width: 9em;
}
#footer .col:first-child,
#footer .footerProducts .col {
    border: 0 none;
}
#footer .col:last-child {
    min-width: 133px;
    padding-right: 0;
}
#footer .col.sitemap {
    width: 31.4%;
    min-width: 289px;
    border-right: 1px solid #646464;
    min-height: 12.6em;
    padding-right: 0;
    margin-right: 2.6%;
}
#footer .sitemap ul.nav a {
    margin-right: 0.75em;
}
#footer p {
    margin: 1em 0;
    line-height: 1.5;
}

#footer .nav a,
#footer .social a {
    padding-top: 0;
    padding-bottom: 0;
}

#footer .footerProducts .contentWrap {
    margin-top: -0.5em;
    margin-bottom: -0.3em;
}

.list3ColsWrap,
.list2ColsWrap {
    /* the parent of the list and immediately preceeding floated element must be
       floated or absolutely positioned or else the section disappears in
       Firefox 1.0 and Opera 7.2-8.x. */
    float: left;
    width: 100%;
    margin: 0 0 1em;
}
* > .list3ColsWrap,
* > .list2ColsWrap {
    margin-top: -1em;
}
.list3ColsWrap .listColShim,
.list2ColsWrap .listColShim {
    float: left;
    width: 100%;
    height: 1em;
}
.list3ColsWrap li,
.list2ColsWrap li {
    /* setting a height in this rule will break this in Opera */
    position: relative;
    display: block;
    float: none;
    width: 33.3%;
}
.list2ColsWrap li {
    width: 50%;
}
.list2ColsWrap li li {
    width: auto;
    display: list-item;
}
.list3ColsWrap li.col2 {
    margin-left: 33.3%;
}
.list3ColsWrap li.col3 {
    margin-left: 66.6%;
}
.list2ColsWrap li.col2 {
    margin-left: 50%;
}
.list3ColsWrap li.col1:first-child,
.list3ColsWrap .col1 + li.col2,
.list3ColsWrap .col2 + li.col3,
.list2ColsWrap li.col1:first-child,
.list2ColsWrap .col1 + li.col2 {
    margin-top: -9999px;
    clear: left;
}
.list3ColsWrap li.col1:first-child:before,
.list3ColsWrap .col1 + li.col2:before,
.list3ColsWrap .col2 + li.col3:before,
.list2ColsWrap li.col1:first-child:before,
.list2ColsWrap .col1 + li.col2:before {
    /* this fixes disappearing items in Firefox 1.0 and Opera */
    clear: left;
    display: block;
    content: '.';
    height: 0;
    visibility: hidden;
}

#footer .sitemap li,
#footer .sitemap li:first-child {
    border: 0 none;
    padding: 0;
}
#footer .sitemap a {
    padding: 0;
}

/***
        IE8 and older fixes
***/

* ~ html .twoCols .col,
* ~ html .threeCols .col,
* ~ html .fourCols .col,
* ~ html #footer .section .col {
    display: inline-block;
}
* ~ html .twoCols .col,
* ~ html .threeCols .col,
* ~ html .fourCols .col,
* ~ html #footer .section .col {
    display: inline;
}

* ~ html .list3ColsWrap li.col1,
* ~ html .list2ColsWrap li.col1 {
    float: left;
    clear: left;
}
* ~ html .list2ColsWrap li.col2 {
    float: right;
    clear: right;
    margin-left: 0;
}
* ~ html .footerProducts .list2ColsWrap li.col2 {
    margin-left: 0;
}
* ~ html .list3ColsWrap li.col2 {
    clear: right;
    float: right;
    left: -33.3%;
    margin-left: 0;
}
* ~ html .list3ColsWrap li.col3 {
    left: 33.3%;
    margin-left: 0;
    margin-right: 0;
}
* ~ html .list3ColsWrap li.col1:first-child,
* ~ html .list3ColsWrap .col1 + li.col2,
* ~ html .list3ColsWrap .col2 + li.col3 {
    margin-top: 0;
    clear: none;
}
* html #footer .list3ColsWrap {
}
* html .list3ColsWrap li.col1,
* html .list2ColsWrap li.col1 {
    float: left;
    clear: left;
}
* html .list2ColsWrap li.col2 {
    float: right;
    clear: right;
    margin-left: 0;
}
* html .footerProducts .list2ColsWrap li.col2 {
    margin-left: 0;
}
* html .list3ColsWrap li.col2 {
    clear: right;
    float: right;
    left: -34%;
    margin-left: 0;
    margin-right: -3px;
}
* html .list3ColsWrap li.col3 {
    left: 33.3%;
    margin-left: 33.3%;
    margin-right: 0;
}
* html #headerWrapper,
* html #footerWrapper {
    width: 100%;
}
