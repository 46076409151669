@import "fonts/font-awesome";
@import "fonts/geared-slab";
@import "modules/filter-page";
@import "modules/idangerous.swiper";
@import "mobile";
@import "modules/slick";

@media (min-width: 600px) {
    @import "desktop";
}
